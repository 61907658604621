/* Main type for a timestamp that represents a real unit of time - this uses the number of milliseconds after Unix epoch UTC w/o leap seconds */

import { SortedArray } from './sorted-array';
import { Emitter } from '@sqior/js/event';

export type ClockTimestamp = number;

/* Get the current timestamp */

export function now(): ClockTimestamp {
  return Date.now();
}

export function secondsNow(): ClockTimestamp {
  return Math.floor(now() / 1000);
}

/* Manipulate a timestamp with a defined unit of time */

export function addMilliseconds(milliSeconds: number, timestamp: ClockTimestamp = 0) {
  return timestamp + milliSeconds;
}
export function addSeconds(seconds: number, timestamp: ClockTimestamp = 0) {
  return addMilliseconds(seconds * 1000, timestamp);
}
export function addMinutes(minutes: number, timestamp: ClockTimestamp = 0) {
  return addSeconds(minutes * 60, timestamp);
}
export function addHours(hours: number, timestamp: ClockTimestamp = 0) {
  return addMinutes(hours * 60, timestamp);
}
export function addDays(days: number, timestamp: ClockTimestamp = 0) {
  return addHours(days * 24, timestamp);
}
export function inSeconds(timestamp: ClockTimestamp) {
  return timestamp / 1000;
}
export function inMinutes(timestamp: ClockTimestamp) {
  return inSeconds(timestamp) / 60;
}
export function inHours(timestamp: ClockTimestamp) {
  return inMinutes(timestamp) / 60;
}
export function inDays(timestamp: ClockTimestamp) {
  return inHours(timestamp) / 24;
}

/* Interface for time providers */

export type TimerCallback = () => void;
export type StopTimer = () => void;

export interface TimerInterface {
  get now(): ClockTimestamp;
  schedule(callback: TimerCallback, ms: ClockTimestamp): StopTimer;
  periodic(callback: TimerCallback, ms: ClockTimestamp): StopTimer;
}

/* Time provider with the default clock */

const MaxTimerPeriod = addHours(500);

export function scheduleAt(
  timer: TimerInterface,
  callback: TimerCallback,
  time: number
): StopTimer {
  const timeoutIn = time - timer.now;
  if (timeoutIn > 0) return timer.schedule(callback, timeoutIn);
  return () => {
    // nop
  };
}

export class StdTimer implements TimerInterface {
  get now() {
    return Date.now();
  }

  schedule(callback: TimerCallback, timeout = 0): StopTimer {
    let stopTimer: ReturnType<typeof setTimeout> | undefined;
    /* Check if the maximum supported timeout period is not exceeded */
    if (timeout < MaxTimerPeriod) stopTimer = setTimeout(callback, timeout);
    else {
      /* Define the next iteration */
      const iterate = () => {
        if (timeout <= MaxTimerPeriod) callback();
        else {
          timeout -= MaxTimerPeriod;
          stopTimer = setTimeout(iterate, Math.min(timeout, MaxTimerPeriod));
        }
      };
      stopTimer = setTimeout(iterate, MaxTimerPeriod);
    }
    return () => {
      if (stopTimer) clearTimeout(stopTimer);
    };
  }

  periodic(callback: TimerCallback, period: ClockTimestamp): StopTimer {
    const stopInteval = setInterval(callback, period);
    return () => {
      clearInterval(stopInteval);
    };
  }
}

/* Test helper class that simulates a timer with a controlled clock */

export class TestTimer implements TimerInterface {
  constructor(current = now(), scale = 0) {
    this.time = current;
    /* If the timer shall automatically be advanced, set a normal periodic timer for that */
    if (scale) {
      this.stdTimer = new StdTimer();
      this.lastTime = this.stdTimer.now;
      this.advancer = this.stdTimer.periodic(() => {
        if (!this.stdTimer) return;
        const currentTime = this.stdTimer.now;
        this.time += (currentTime - this.lastTime) * scale;
        this.handleTimers();
        this.lastTime = currentTime;
      }, addMilliseconds(100));
    }
  }

  close() {
    if (this.advancer) {
      this.advancer();
      this.advancer = undefined;
    }
  }
  get closed(): boolean {
    return !this.advancer;
  }

  get now() {
    return this.time;
  }

  schedule(callback: TimerCallback, timeout = 0): StopTimer {
    const time = this.time + timeout;
    SortedArray.insert<[ClockTimestamp, TimerCallback]>(this.timers, [time, callback], (a, b) => {
      return a[0] < b[0];
    });
    return () => {
      const idx = this.timers.findIndex((v) => v[0] === time && v[1] === callback);
      if (idx >= 0) this.timers.splice(idx, 1);
    };
  }

  /** Starts a periodic test timer */
  periodic(callback: TimerCallback, ms: number): StopTimer {
    /* Define the function that starts the next period */
    let lastTime = this.now;
    let stopTimer: StopTimer | undefined;
    const iterate = () => {
      lastTime = this.now;
      stopTimer = this.schedule(iterate, ms);
      callback();
    };
    /* Observe modifications to pot. re-schedule if time is set back */
    const stopModify = this.modified.on(() => {
      if (this.now >= lastTime) return;
      /* Stop a pot. running timer */
      stopTimer?.();
      /* Iterate (will call callback right away and reschedule) */
      iterate();
    });
    /* Schedule a timer for the next period */
    stopTimer = this.schedule(iterate, ms);
    return () => {
      stopTimer?.();
      stopModify();
    };
  }

  advance(ms: ClockTimestamp) {
    this.set(this.time + ms);
  }

  set(ms: ClockTimestamp) {
    this.time = ms;
    this.lastTime = this.stdTimer?.now ?? 0;
    this.handleTimers();
    this.modified.emit(this.time);
  }

  private handleTimers() {
    while (this.timers.length) {
      const timer = this.timers[0];
      if (timer[0] > this.time) return;
      this.timers.shift();
      timer[1]();
    }
  }

  tick() {
    this.advance(0);
  }

  private time;
  private timers: [ClockTimestamp, TimerCallback][] = [];
  private advancer?: () => void;
  private stdTimer?: StdTimer;
  private lastTime: ClockTimestamp = 0;
  readonly modified = new Emitter<[ClockTimestamp]>();
}

/** Holder of a stop timer callback */

export class TimerHolder {
  /** Sets the timer result */
  set(stopTimer: StopTimer) {
    if (this.stopTimer !== undefined) this.stopTimer();
    this.stopTimer = stopTimer;
  }

  /** Checks if a timer is pending */
  get isSet() {
    return this.stopTimer !== undefined;
  }

  /** Resets the timer */
  reset() {
    if (this.stopTimer === undefined) return;
    this.stopTimer();
    this.stopTimer = undefined;
  }

  private stopTimer?: StopTimer;
}

/** Debounces a function by only calling it if no other call was received for some time period */

export function debounce(timer: TimerInterface, func: () => void, duration: ClockTimestamp) {
  const holder = new TimerHolder();
  return () => {
    /* Reset the holder */
    holder.set(timer.schedule(func, duration));
  };
}
