import BedView from '../ward-overview-dashboard/components/bed-view/bed-view';
import { BedAllocationWardListItemBed } from '@sqior/viewmodels/occupancy';
import { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { OperationContext } from '@sqior/react/operation';
import { OperationSpec } from '@sqior/js/operation';
import { ValueObject } from '@sqior/js/data';
import { MessengerTabPageSpec, MessengerTabStack } from '@sqior/viewmodels/app';
import styles from './bed-view-multi-alloc.module.css';
import { useMessengerTabState } from '@sqior/react/uimessenger';

export interface BedViewMultiAllocProps {
  bed: BedAllocationWardListItemBed;
}

export function BedViewMultiAlloc({ bed }: BedViewMultiAllocProps) {
  const tabMessenger = useMessengerTabState();
  const dispatcher = useContext(OperationContext);
  const [selectedId, setSelectedId] = useState<string>('');

  const otherPatientId = bed.other?.[0]?.id;

  const onBedClick = () => {
    if (!bed) return;

    const dhlId = extractDHLPatientId(tabMessenger);
    const currentPatientId = getCurrentPatientId(bed);
    const otherPatientId = getOtherPatientId(bed);

    if (dhlId !== otherPatientId && selectedId === otherPatientId) {
      if (!currentPatientId || !bed.select) return;
      const otherSelect = createSelectionForOtherPatient(bed);
      if (!otherSelect) return;
      setSelectedId(otherPatientId);
      dispatcher.start(otherSelect);
      return;
    }
    if (dhlId !== currentPatientId && selectedId === currentPatientId) {
      if (!currentPatientId || !bed.select) return;
      setSelectedId(currentPatientId);
      dispatcher.start(bed.select);
      return;
    }

    if (hasDoubleAllocation(bed) && otherPatientId && selectedId === currentPatientId) {
      const otherSelect = createSelectionForOtherPatient(bed);
      if (!otherSelect) return;
      setSelectedId(otherPatientId);
      dispatcher.start(otherSelect);
      return;
    }
    if (!currentPatientId || !bed.select) return;
    setSelectedId(currentPatientId);
    dispatcher.start(bed.select);
  };

  const getPatientTurn = () => {
    if (otherPatientId && selectedId === otherPatientId) {
      return undefined;
    }
    if (otherPatientId && selectedId !== otherPatientId) {
      return 0;
    }
    return undefined;
  };

  return (
    <div className={styles['container']}>
      <BedView
        key={bed.id}
        bed={bed}
        shadow={bed.other.length > 0}
        onClick={onBedClick}
        other={getPatientTurn()}
      />

      {bed.other && bed.other[0] && (
        <motion.div
          style={{
            position: 'absolute',
            top: 5,
            left: 10,
            width: '100%',
          }}
        >
          <BedView
            bed={bed}
            onClick={onBedClick}
            other={otherPatientId === selectedId ? 0 : undefined}
          />
        </motion.div>
      )}
    </div>
  );
}

export default BedViewMultiAlloc;

const hasDoubleAllocation = (bed: BedAllocationWardListItemBed) => bed.other.length > 0;
const getOtherPatientId = (bed: BedAllocationWardListItemBed): string | undefined =>
  bed.other[0]?.id;
const getCurrentPatientId = (bed: BedAllocationWardListItemBed): string | undefined =>
  bed.current.patient?.id;

const createSelectionForOtherPatient = (bed: BedAllocationWardListItemBed) => {
  if (!hasDoubleAllocation(bed)) return null;
  if (!bed.other[0].id) return null;
  const otherPatientId = getOtherPatientId(bed);
  if (!otherPatientId) return null;
  if (!bed.select?.data) return null;

  const select: OperationSpec<ValueObject> = {
    ...bed.select,
    data: { ...bed.select.data, id: otherPatientId },
  };

  return select;
};

const extractDHLPatientId = (tabMessenger: MessengerTabStack) => {
  try {
    const [_name, data] = tabMessenger;
    const newData = data as MessengerTabPageSpec;
    const id = newData.data?.['id'] as string;
    return id;
  } catch (e) {
    return undefined;
  }
};
