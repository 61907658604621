import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { debounce, StdTimer } from '@sqior/js/data';

// Define the context shape
interface AnimationContextType {
  addStartTime: () => void;
}

// Create the context
const AnimationContext = createContext<AnimationContextType | undefined>(undefined);

// Context provider component
interface AnimationProviderProps {
  children: ReactNode;
}

export const AnimationProvider: React.FC<AnimationProviderProps> = ({ children }) => {
  const [allTimes, setAllTimes] = useState<number[]>([]);

  const addStartTime = () => {
    setAllTimes((prev) => [...prev, new Date().getSeconds()]);
  };

  const debounceFunc = debounce(
    new StdTimer(),
    () => {
      // Check if all times are the same
      const allEqual = allTimes.every((val, i, arr) => val === arr[0]);
      if (!allEqual) {
        setAllTimes([]);
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 1000);
      }
    },
    5000
  );

  useEffect(() => {
    debounceFunc();
  }, [allTimes]);

  return <AnimationContext.Provider value={{ addStartTime }}>{children}</AnimationContext.Provider>;
};

// Hook to use the animation context
export const useSyncAnimation = (): AnimationContextType => {
  const context = useContext(AnimationContext);
  if (context === undefined) {
    throw new Error('useAnimationContext must be used within an AnimationProvider');
  }
  return context;
};
