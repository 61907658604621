import { AuthProvider } from '@sqior/js/authbase';
import { Dispatcher } from '@sqior/js/operation';
import { OperationSender } from '@sqior/js/operation-message';
import { IConfigContext } from '@sqior/js/url';
import { ConnectionState } from '@sqior/js/wsbase';
import { ConfigContext, VersionInfo, VersionInfoContext } from '@sqior/react/utils';
import { ServerConnection } from '@sqior/web/wsclient';
import {
  ServerConnectionContext,
  ServerConnectionObserver,
} from '../server-connection-context/server-connection-context';
import { CoreServicesConfig, CoreServicesProps } from './core-services';
import { SetAppLifecycleListener, WillEnterForeground } from './ios-android-interop';
import { useContext } from 'react';
import { AuthContext } from '@sqior/react/uiauth';
import { OperationContext } from '@sqior/react/operation';
import { TimerProvider } from '@sqior/react/state';
import { InterweaveExtFactoryContext } from '@sqior/react/uibase';
import { ComponentFactory } from '@sqior/react/factory';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { de } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

class PublicConnectionServices {
  constructor(
    config: CoreServicesConfig,
    configContext: IConfigContext,
    authContext: AuthProvider,
    versionInfoClientContext: VersionInfo
  ) {
    /* Create the server connection */
    const sessionId = window.sessionStorage.getItem('sqior.ws.demo.sessionId');
    const uploadEndpoint = configContext.getWSEndpoint(config.webSocketName);
    this.connection = new ServerConnection(uploadEndpoint.href, versionInfoClientContext.version, {
      authContext: authContext,
      sessionId: sessionId === null ? undefined : sessionId,
    });
    this.connection.stateChanged.on((state) => {
      if (state === ConnectionState.CONNECTED)
        if (this.connection.sessionId !== undefined)
          window.sessionStorage.setItem('sqior.ws.demo.sessionId', this.connection.sessionId);
    });

    this.connectionObserver = new ServerConnectionObserver(this.connection, authContext);

    /* Configuration of the operation dispatcher */
    this.dispatcher = new Dispatcher();
    /* Switch operation interface depending on suitability for REST (= binaries) */
    this.dispatcher.register(new OperationSender(this.connection));
    /* Handle the operation once all temporaries are resolved */
    /* Create state overlays for this operation if applicable */
    

    /* Initialize connection observer */
    SetAppLifecycleListener((state: string) => {
      if (state === WillEnterForeground) {
        /* Re-initialize the connection observer to start and give time for reconnecting */
        this.connectionObserver.reinitialize();
        /* If the connection seems to be established, send a ping immediately to validate if it is working
             as there seems to be an issue on iOS that the websocket connection close event is not always
             provided to the web view when the app is paused */
        this.connection.resetPing();
      }
    });
  }
  connection: ServerConnection;
  dispatcher: Dispatcher;
  connectionObserver: ServerConnectionObserver;
}

let pcs: PublicConnectionServices;

export function PublicCoreServices(props: CoreServicesProps) {
  const configContext = useContext(ConfigContext);
  const authContext = useContext(AuthContext);
  if (!pcs)
    pcs = new PublicConnectionServices(
      props.config,
      configContext,
      authContext.provider,
      props.version
    );
  return (
    <VersionInfoContext.Provider value={props.version}>
      <OperationContext.Provider value={pcs.dispatcher}>
        <ComponentFactory.Provider value={props.config.factory.get()}>
          <InterweaveExtFactoryContext.Provider value={props.config.interweaveFactory}>
            <ServerConnectionContext.Provider value={pcs.connectionObserver}>
              <TimerProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                  {props.children}
                </LocalizationProvider>
              </TimerProvider>
            </ServerConnectionContext.Provider>
          </InterweaveExtFactoryContext.Provider>
        </ComponentFactory.Provider>
      </OperationContext.Provider>
    </VersionInfoContext.Provider>
  );
}
