import ChatList from '../chat-list/chat-list';
import styles from './chat-page.module.css';
import { useChats } from '@sqior/react/utils';

export interface ChatPageProps {
  type?: 'support' | 'all';
}

export function ChatPage({ type }: ChatPageProps) {
  const { chats } = useChats({ type });

  return (
    <div className={styles['container']}>
      <ChatList chats={chats} />
    </div>
  );
}

export default ChatPage;
