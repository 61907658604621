import { OperationSpec, OperationType } from '@sqior/js/operation';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { ValueObject } from '@sqior/js/data';

export const UserPairedPhonePath = 'paired-device';
export const UserPairedPhoneStatePath = UserPairedPhonePath;
export const UserPairedPhoneOpPath = UserPairedPhonePath;

export const DeviceListPath = 'paired-device-list';
export const DeviceListStatePath = DeviceListPath;

export const DeletePairedPhonePath = 'delete-paired-phone';
export const DeletePairedPhoneOpPath = DeletePairedPhonePath;

export const UserDeviceListVMType = 'UserDeviceListVMType';

export const AnonymousToken = 'anonymous';

export const PairingFeaturePublicServerUrlPath = 'pairing-feature-public-server-url';

export type PairingToken = {
  sub: string;
  typ: string;
  name: string;
  customer: string;
  iss: string;
};

export type SignedToken = PairingToken & {
  iat: number;
  exp: number;
};

export type QRToken = {
  otp: string;
  cst: string;
  iat: number;
};

export type UserDevice = {
  model?: string;
  manufacturer?: string;
  deviceName?: string;
};

export type UserDeviceListItem = {
  id: Entity;
  customDeviceName: string;
  genericDeviceName: string;
  lastConnected: number;
  pairedAt: number;
  active: boolean;
};

export type UserDeviceListVM = EntityHeader & {
  devices: UserDeviceListItem[];
};

export const EmptyUserDeviceList: UserDeviceListVM = {
  entityType: UserDeviceListVMType,
  devices: [],
};

export type QRTokenData = { token: string; device?: UserDevice };

export function PairPhone(qrToken: string, device?: UserDevice): OperationSpec<QRTokenData> {
  const data: QRTokenData = {
    token: qrToken,
  };
  if (device) data.device = device;
  return { type: OperationType.Add, path: UserPairedPhoneOpPath, data };
}

export function RemovePairing(id: Entity): OperationSpec<ValueObject> {
  return {
    type: OperationType.Delete,
    path: DeletePairedPhoneOpPath,
    data: { id: 'not used', data: id },
  };
}

export function createDeviceName(device: UserDevice | undefined, fallback: string) {
  if (!device) return fallback;

  const nameParts = [device.manufacturer];
  nameParts.push(device.deviceName ?? device.model);
  const returnName = nameParts.join(' ');

  return returnName.trim() ? returnName : fallback;
}

export function createDeviceNamePair(device: UserDevice | undefined, fallback: string) {
  if (!(device && device.manufacturer && device.deviceName))
    return {
      customName: fallback,
      genericName: fallback,
    };

  const model = device.model
    ? device.manufacturer + ' ' + device.model
    : device.manufacturer.toLowerCase() === 'apple'
    ? 'iPhone'
    : 'Android';
  return {
    customName: device.deviceName,
    genericName: model,
  };
}
