import { EntityHeader } from '@sqior/js/entity';
import { ProcessGraph } from './process-graph';

export const OccupancyAnalyticsDashboard = 'OccupancyAnalyticsDashboard';
export const ORWorkflowAnalyticsDashboard = 'ORWorkflowAnalyticsDashboard';

export type ORWorkflowAnalyticsDashboardVM = EntityHeader & {
  graph: ProcessGraph;
};

export type OccupancyAnalyticsDashboardVM = EntityHeader & OccupancyAnalyticsData;

export type OccupancyAnalyticsData = {
  wards: ListSelectionData<ListData[]>;
  timeRanges: ListSelectionData<TimeRange[]>;
  stats: WardStatDataBase;
};

export type ListSelectionData<T> = {
  value: T;
  selected: number;
};

export type ListData = {
  id: string;
  label: string;
};

export type TimeRange = {
  id: string;
  label: string;
  start: number;
  end: number;
  index: number;
};

export enum OccupancyMetricCardTypes {
  Spi = 'spi',
  OccupancyRate = 'occupancyRate',
  StayDuration = 'stayDuration',
}

export type WardStatDataBase = Record<OccupancyMetricCardTypes, MetricCardStats>;

export type MetricCardStats = {
  avg: number;
  current: number;
  chart: MetricChartEntry[];
  trend?: MetricChartTrend;
};

export type MetricChartEntry = {
  timestamp: number;
  value?: number;
};

export type MetricChartTrend = {
  percentage: number;
  hasIncreased: boolean;
  text: string;
};
