import { Node } from 'reactflow';
import { Edge } from '@reactflow/core/dist/esm/types/edges';

export type WithSelection<T> = {
  value: T;
  selected: number;
};

export const withSelection = <T>(value: T, selected: number): WithSelection<T> => {
  return { value, selected };
};

interface MakeFlowChartProps {
  edges: Edge[];
  nodes: Node[];
  areas?: Node[];
}

interface MakeFlowChartResult {
  mEdges: Edge[];
  mNodes: Node[];
  mAreas?: Node[];
}

export const makeManualPosition = ({
  edges,
  nodes,
  areas,
}: MakeFlowChartProps): MakeFlowChartResult => {
  const rootX = 0;

  const nodeWidth = 150;
  const nodeHeight = 50;

  const verticalGap = 200;

  const horizontalGap = 150;

  const allNodes: Node[] = [];

  const maxY = Math.max(...nodes.map((node) => node.position?.y ?? 0));

  for (let j = 0; j <= maxY; j++) {
    const allNodesInRow = nodes.filter((node) => node.position?.y === j);
    // set the x position of the nodes that the root node will be the center of the row
    const rowWidth = allNodesInRow.length * (nodeWidth + horizontalGap);
    const startX = rootX - rowWidth / 2;
    for (let i = 0; i < allNodesInRow.length; i++) {
      const nodeX = startX + i * (nodeWidth + horizontalGap);
      const nodeY = j * (nodeHeight + verticalGap);
      const node = allNodesInRow[i];
      if (node) {
        allNodes.push({
          ...node,
          position: {
            x: nodeX,
            y: nodeY,
          },
        });
      }
    }
  }

  return { mNodes: allNodes, mEdges: edges, mAreas: areas };
};
