import { AddOperation, OperationFactory, OperationState } from '@sqior/js/operation';
import { QRScannerControl } from '@sqior/react/qrcode';
import { PairPhone, UserDevice } from '@sqior/viewmodels/app';
import styles from './qr-scanner-pairing-page.module.css';

export type QrPairingPageProperties = {
  device: UserDevice;
  tokenCallback: (s: string) => void;
};

export function QrPairingPage(props: QrPairingPageProperties) {
  function operationCreator(code: string): { op: AddOperation; path: string } {
    const opSpec = PairPhone(code, props.device);
    const op = OperationFactory.create(opSpec.type, opSpec.data) as AddOperation;
    op.stateChange.on((state) => operationCallback(state, op));
    return { op, path: opSpec.path };
  }

  function operationCallback(state: OperationState, addOperation: AddOperation) {
    if (state === OperationState.Completed && addOperation.finalId)
      props.tokenCallback(addOperation.finalId); // saving token to storage
  }

  return (
    <div className={styles['container']}>
      <div className={styles['title']}>QR-Code scannen</div>
      <QRScannerControl
        operationCreator={operationCreator}
        onClose={() => {
          /**  */
        }}
      />
      <div className={styles['description']}>
        Melde dich bei sqior auf deinem Desktop oder Diensttelefon an und wähle Menü → verknüpfte
        Geräte → Neues Gerät hinzufügen.
        <br />
        Scanne den QR-Code um dein Gerät zu verknüpfen.
      </div>
    </div>
  );
}

export default QrPairingPage;
