import { Logger } from '@sqior/js/log';

/* Base64 encoded device token */
let Token; // [token, system]
let TokenSetter;

/* Export function that allows to register a callback which is called when the device token is set,
   the current value is returned as in many cases this will already have been set */
export function SetTokenSetter(setter) {
  TokenSetter = setter;
  return Token;
}

/* Expose function for the app to provide the device token that can be used to send push notifications */
window.SetDeviceToken = (tokenBase64, system) => {
  if (system) Token = [tokenBase64, system];
  else Token = [tokenBase64, 'APN'];

  if (TokenSetter) TokenSetter(Token);
};

export let SqiorNativeAppVersion = undefined; // see SqiorNativeAppVersionType
window.SetNativeAppInfo = (version) => {
  Logger.debug(['Native app info provided', version]);
  if (typeof version === 'string') {
    SqiorNativeAppVersion = { version: version };
  } else if ('version' in version) {
    SqiorNativeAppVersion = version;
  }
};

let OperationPerformer;
let OperationStr; // operation
export function SetOperationPerformer(performer) {
  //console.log('SetOperationPerformer():', JSON.stringify(performer));
  OperationPerformer = performer;
  // If operation was set before, start that operation (connection with server might take some time until SetOperationPerformer() gets called)
  if (OperationStr && OperationPerformer) {
    //console.log('Executing spooled operation: ', OperationStr);
    OperationPerformer(OperationStr, PerformOperationCB);
  }
}
export function PerformOperation(operationStr) {
  //console.log('window.PerformOperation(): ', operationStr);

  if (OperationPerformer !== undefined) {
    //console.log('--OperationPerformer defined, startinng operation: ', operationStr);
    OperationPerformer(operationStr, PerformOperationCB);
  } else {
    /*console.log(
      '--OperationPerformer NOT defined, storing operation for later execution: ',
      JSON.stringify(operationStr)
    );*/
    // Remember the operation and callback until SetOperationPerformer() gets called
    OperationStr = operationStr;
  }
  return true;
}
window.PerformOperation = PerformOperation;

function PerformOperationCB(operationStr, result) {
  // string, string
  //console.log('PerformOperationCB(): ', operationStr, result);
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.operationResult
  ) {
    window.webkit.messageHandlers.operationResult.postMessage(operationStr);
  } else if (window.Android && window.Android.onCBPerformOperation) {
    window.Android.onCBPerformOperation(operationStr, result);
  }
}

/* App lifecycle events */

export const WillEnterForeground = 'foreground';
export const DidEnterBackground = 'background';

let AppLifecycleListener;

// Called to register a listener for an app lifecycle event
export function SetAppLifecycleListener(listener) {
  AppLifecycleListener = listener;
}

// Called from the native app to notify about a lifecycle event
window.NotifyAppLifecycle = (state) => {
  if (AppLifecycleListener) AppLifecycleListener(state);
};

/* Propagate back to the application whether back is possible*/

export function NativeAppBackPossible(backPossible) {
  // boolean
  //console.log('NativeAppBackPossible(): ', backPossible);
  if (window.Android && window.Android.onBackPossible) {
    window.Android.onBackPossible(backPossible === true);
  }
}

export function isSqiorAppleClient() {
  // webkit is available on our sqior iPhone app only
  return window.webkit && window.webkit.messageHandlers;
}

export function isSqiorAndroidClient() {
  // On Android, the useragent is set to "sqior-android"
  return navigator.userAgent !== undefined && navigator.userAgent.includes('sqior');
}

export function isSqiorMobileClient() {
  return isSqiorAndroidClient() || isSqiorAppleClient();
}

window.LogFromNative = (log, idx) => {
  Logger.info(['NativeApp', log]);
  if (window.Android && window.Android.onCBLogFromNative) {
    window.Android.onCBLogFromNative(idx);
  }
};
