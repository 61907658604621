import { PluginContext } from '@sqior/js/plugin';
import { StateOverlayContext } from '@sqior/js/state-operation';
import { FactoryContext } from '@sqior/react/factory';
import { UISelectionPlugin } from '@sqior/react/uiselection';
import {
  ORWorkflowDashboardType,
  ORWorkflowInteractiveDashboardType,
  ORWorkflowIOMListItemType,
  ORWorkflowMyListItemType,
  ORWorkflowORClusterListItemType,
  ORWorkflowORListItemType,
  OrWorkflowOverviewDashboardResponsive,
  ORWorkflowOverviewPreliminaryProcedureType,
  ORWorkflowOverviewProcedureType,
  ORWorkflowOverviewSutureIncisionDurationType,
  ORWorkflowOverviewType,
  ORWorkflowStageItemType,
  PreliminaryProcedureListItemType,
} from '@sqior/viewmodels/orworkflow';
import { PatientOverviewPath } from '@sqior/viewmodels/patient';
import { WorkflowCommandPath, WorkflowPathOverlay } from '@sqior/viewmodels/workflow';
import { lazy } from 'react';
import ORWorkflowListItem from './orworkflow-list-item/orworkflow-list-item';
import ORWorkflowArea from './orworkflow-area/orworkflow-area';

const ORWorkflowOverviewDashboardResponsive = lazy(() =>
  import('../bundle').then((module) => ({
    default: module.ORWorkflowOverviewDashboardResponsive,
  }))
);

const ORWorkflowInteractiveDashboard = lazy(() =>
  import('../bundle').then((module) => ({ default: module.ORWorkflowInteractiveDashboard }))
);
const ORWorkflowOverviewSutureIncisionDuration = lazy(() =>
  import('../bundle').then((module) => ({
    default: module.ORWorkflowOverviewSutureIncisionDuration,
  }))
);
const ORWorkflowOverviewProcedure = lazy(() =>
  import('../bundle').then((module) => ({ default: module.ORWorkflowOverviewProcedure }))
);

const ORWorkflowDashboard = lazy(() =>
  import('../bundle').then((module) => ({ default: module.ORWorkflowDashboard }))
);

export function UIWorkflowPlugin(context: PluginContext & FactoryContext & StateOverlayContext) {
  context.plugins.require(UISelectionPlugin, context);
  context.factory.add(OrWorkflowOverviewDashboardResponsive, ORWorkflowOverviewDashboardResponsive);

  context.factory.add(ORWorkflowOverviewType, ORWorkflowArea);
  context.factory.add(ORWorkflowStageItemType, ORWorkflowListItem);
  context.factory.add(ORWorkflowORListItemType, ORWorkflowListItem);
  context.factory.add(ORWorkflowORClusterListItemType, ORWorkflowListItem);
  context.factory.add(ORWorkflowMyListItemType, ORWorkflowListItem);
  context.factory.add(ORWorkflowIOMListItemType, ORWorkflowListItem);
  context.factory.add(PreliminaryProcedureListItemType, ORWorkflowListItem);
  context.factory.add(ORWorkflowDashboardType, ORWorkflowDashboard);
  context.factory.add(ORWorkflowOverviewProcedureType, ORWorkflowOverviewProcedure);
  context.factory.add(ORWorkflowOverviewPreliminaryProcedureType, ORWorkflowOverviewProcedure);
  context.factory.add(ORWorkflowInteractiveDashboardType, ORWorkflowInteractiveDashboard);
  context.factory.add(
    ORWorkflowOverviewSutureIncisionDurationType,
    ORWorkflowOverviewSutureIncisionDuration
  );
  context.stateOverlays.add(WorkflowCommandPath, WorkflowPathOverlay, PatientOverviewPath);
}
