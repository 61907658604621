import React, { FC } from 'react';
import {
  BedAllocationWardListItemBed,
  BedAllocationWardListItemBedState,
} from '@sqior/viewmodels/occupancy';
import styles from './bed-view.module.css';
import { PersonSex } from '@sqior/viewmodels/person';
import { useUIGlobalState } from '@sqior/react/state';
import { motion } from 'framer-motion';
import NextBedView from '../next-bed-view/next-bed-view';
import { SqiorBedLineView } from '@sqior/react/uibase';
import CurrentBedView from '../current-bed-view/current-bed-view';

export enum PillColor {
  Male = '#1CADE4',
  Female = '#ED6F8D',
  Unisex = '#AEB0BD',
}

type Props = {
  bed: BedAllocationWardListItemBed;
  other?: number;
  shadow?: boolean;
  onClick?: () => void;
};

const MINIMAL_HEIGHT = 55;
const EXPANDED_HEIGHT = 75;

const NEXT_BED_HEIGHT = 18;

const BedView: FC<Props> = ({ bed, other, shadow, onClick }) => {
  const { UIGlobalState } = useUIGlobalState();

  const getSexColor = () => {
    const sexToCheck =
      other !== undefined && bed.other[other]?.sex
        ? bed.other[other].sex
        : (bed.current.sex as PersonSex);
    if (sexToCheck === PersonSex.Male) return PillColor.Male;
    if (sexToCheck === PersonSex.Female) return PillColor.Female;
    return PillColor.Unisex;
  };

  const getShowCount = () => {
    const values: boolean[] = [
      UIGlobalState.bmShowSpeciality,
      UIGlobalState.bmShowInsurance,
      UIGlobalState.bmShowSpi,
    ];

    return values.filter((value) => value).length;
  };

  const showCount = getShowCount();

  const expanded = showCount > 1;

  const getBackgroundColor = () => {
    if (!bed) return undefined;
    switch (bed.current.state) {
      case BedAllocationWardListItemBedState.Free:
        return 'rgb(35,44,75)';
      default:
        return undefined;
    }
  };

  const getBorderColor = () => {
    if (!bed) return undefined;
    switch (bed.current.state) {
      case BedAllocationWardListItemBedState.Free:
        return 'rgba(0,0,0,0.29)';
      default:
        return undefined;
    }
  };

  const getWhileTab = () => {
    if (!bed) return undefined;
    if (other === undefined) return undefined;

    switch (bed.current.state) {
      case BedAllocationWardListItemBedState.Free:
        return undefined;
      case BedAllocationWardListItemBedState.Blocked:
        return undefined;
      default:
        return { scale: 0.95 };
    }
  };

  const getLineVisibility = (): boolean | undefined => {
    if (!bed) return undefined;
    switch (bed.current.state) {
      case BedAllocationWardListItemBedState.Blocked:
        return false;
      default:
        return undefined;
    }
  };

  const getHeight = () => {
    const hasDouble = hasDoubleAllocation(bed);

    if (hasDouble && expanded) return EXPANDED_HEIGHT - 5;
    if (hasDouble) return MINIMAL_HEIGHT - 5;

    if (expanded) return EXPANDED_HEIGHT;
    return MINIMAL_HEIGHT;
  };

  return (
    <motion.div
      whileTap={getWhileTab()}
      onMouseDown={() => onClick?.()}
      layout="preserve-aspect"
      className={styles['container-wrapper']}
      style={{
        opacity: shadow ? 0.5 : 1,
      }}
    >
      <div
        className={styles['container']}
        style={{
          height: getHeight(),
          backgroundColor: getBackgroundColor(),
          borderColor: getBorderColor(),
          marginRight: hasDoubleAllocation(bed) ? 10 : 0,
        }}
      >
        <div className={styles['content']}>
          <SqiorBedLineView backgroundColor={getSexColor()} visibility={getLineVisibility()} />
          <CurrentBedView bed={bed} expanded={expanded} other={other} />
        </div>

        <div
          className={styles['room-index']}
          style={{
            backgroundColor: getBackgroundColor(),
          }}
        >
          {bed.current.dischargeInfo?.annotation && other === undefined && (
            <div className={styles['bubble']} />
          )}
          <div>{bed.label}</div>
        </div>
      </div>

      <div
        className={styles['next-bed-wrapper']}
        style={{
          height: NEXT_BED_HEIGHT / 2,
        }}
      >
        {other === undefined && <NextBedView bed={bed} height={NEXT_BED_HEIGHT} />}
      </div>
    </motion.div>
  );
};

export default BedView;
const hasDoubleAllocation = (bed: BedAllocationWardListItemBed) => bed.other.length > 0;
