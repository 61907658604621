import styles from './flow-chart-label.module.css';
import { FactoryProps } from '@sqior/react/factory';
import { EdgeDataTypeStatistic } from '@sqior/viewmodels/analytics-dashboard';

export type FlowChartLabelProps = FactoryProps<EdgeDataTypeStatistic>;

export function FlowChartLabel({
  data: { average, deviation, count, percentage },
}: FlowChartLabelProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['label']}>
        <span className={styles['average']}>{average}</span>
      </div>

      <div className={styles['row']}>
        <div className={styles['label']}>
          <span className={styles['deviation']}>{deviation}</span>
        </div>
        <div className={styles['label']}>
          <span className={styles['count']}>{count}</span>
        </div>
        <div className={styles['label']}>
          <span className={styles['percentage']}>{Math.round(percentage * 100)}%</span>
        </div>
      </div>
    </div>
  );
}

export default FlowChartLabel;
