import { BearerTokenAuthContext, IAuthContext } from '@sqior/js/authbase';
import { ClockTimestamp, now } from '@sqior/js/data';
import { Logger } from '@sqior/js/log';

export class PublicAuthProvider extends BearerTokenAuthContext implements IAuthContext {
  constructor(token: string, sessionStart = now()) {
    super();
    this.token = token;
    this.sessionStart = sessionStart;
    this.initialized = false;
  }

  override async getIdentityToken(): Promise<string | undefined> {
    return undefined;
  }
  async generateToken(scope = '') {
    Logger.info('generating token');
    return { token: this.token, sessionStart: this.sessionStart };
  }

  refreshToken(token: string) {
    this.tokenRefreshed.emit(token);
  }
  tryLogIn(user?: string | undefined): void {
    Logger.info('Attempt to log in');
  }
  logOut(): void {
    PublicAuthProvider.storage.removeItem('pairingToken');
    Logger.info('Logging out');
    window.location.reload();
  }
  authFailedReloadPeriod(): number {
    return 0;
  }

  static get storage() {
    return window.localStorage ?? window.sessionStorage;
  }

  public initialized: boolean;
  readonly isAuthenticated = new Promise<boolean>((resolve) => resolve(true));
  private readonly token: string;
  readonly userInfo = { name: 'anonymous' };
  private readonly sessionStart?: ClockTimestamp;
}
