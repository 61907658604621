import styles from './app-drawer.module.css';
import {
  SvgChart,
  SvgIconChat,
  SvgIconDashboard,
  SvgIconTasks,
  useTextResources,
} from '@sqior/react/uibase';
import { Badge, BadgeOrigin, Button } from '@mui/material';
import { MessengerTab, OpenPage, SelectMessengerTab } from '@sqior/viewmodels/app';
import { firstPage, useMessengerTabState } from '../messenger-tab-stack';
import React, { FC, useContext, useEffect } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { AnimatePresence, motion } from 'framer-motion';
import {
  AppStyle,
  useAppStyle,
  useDynamicState,
  useDynamicStateRaw,
  useUIGlobalState,
} from '@sqior/react/state';
import {
  ChatListPath,
  EnrichedChatVM,
  InformProjectionVM,
  isChatMessage,
  mainInfoContains,
  ReadConfirmationTypes,
} from '@sqior/viewmodels/communication';
import { useAutoMarkRead } from '@sqior/react/uiconversation';
import { useTabTitleCounter } from '@sqior/react/notifications';
import { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { ReactComponent as BurgerIcon } from './menu.svg';
import { useIsMobile } from '@sqior/react/hooks';
import {
  AnalyticsDashboards,
  AnalyticsDashboardSelectionPath,
} from '@sqior/viewmodels/analytics-dashboard';

const ANCHOR_ORIGIN: BadgeOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const COMPONENT_PROPS = {
  badge: {
    style: {
      color: 'white',
      top: 12,
    },
  },
};

const ICON_STYLE = {
  width: 35,
  height: 35,
};

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 25,
  },
}));

export const getUnreadMessages = (chatList: EnrichedChatVM[]) => {
  return chatList.reduce((accumulator, currentObject) => {
    return accumulator + currentObject.unread;
  }, 0);
};

/* eslint-disable-next-line */
export interface AppDrawerProps {}

export function AppDrawer(props: AppDrawerProps) {
  const isMobile = useIsMobile();
  const { setAppStyle } = useAppStyle();
  const { UIGlobalState, setUIGlobalState } = useUIGlobalState();
  const chatList = useDynamicStateRaw<EnrichedChatVM[]>(ChatListPath);
  const tabStack = useMessengerTabState();

  const textDict = useTextResources();
  const dispatcher = useContext(OperationContext);
  const isAnalyticsAllowed =
    useDynamicState<AnalyticsDashboards[]>(AnalyticsDashboardSelectionPath, []).length > 0;

  const activeMessengerTab = firstPage(tabStack);

  useEffect(() => {
    if (isMobile) {
      setAppStyle(AppStyle.Messenger);
      return;
    }

    if (activeMessengerTab === MessengerTab.Analytics) {
      setAppStyle(AppStyle.Analytics);
    } else {
      setAppStyle(AppStyle.Combined);
    }
  }, [activeMessengerTab, isMobile, setAppStyle]);

  const mainInfo = useDynamicState<InformProjectionVM[]>('main-info/items', []);
  useAutoMarkRead(mainInfo, ReadConfirmationTypes.Implicit);
  useTabTitleCounter(mainInfo.filter((item) => mainInfoContains(item)).length);

  const tasksCount = mainInfo.filter(
    (item) => mainInfoContains(item) && !isChatMessage(item)
  ).length;

  const tabs = [
    {
      label: textDict.get('tab_dashboard'),
      value: MessengerTab.Analytics,
      icon: (
        <SvgChart
          className={
            styles[activeMessengerTab === MessengerTab.Analytics ? 'tab-active' : 'tab-icon']
          }
          style={ICON_STYLE}
        />
      ),
      badgeContent: 0,
    },
    {
      label: textDict.get('tab_task_label'),
      value: MessengerTab.Todo,
      icon: (
        <SvgIconTasks
          className={styles[activeMessengerTab === MessengerTab.Todo ? 'tab-active' : 'tab-icon']}
          style={ICON_STYLE}
        />
      ),
      badgeContent: tasksCount,
    },
    {
      label: textDict.get('tab_list_label'),
      value: MessengerTab.List,
      icon: (
        <SvgIconDashboard
          className={styles[activeMessengerTab === MessengerTab.List ? 'tab-active' : 'tab-icon']}
          style={ICON_STYLE}
        />
      ),
      badgeContent: 0,
    },
  ];
  if (chatList)
    tabs.push({
      label: textDict.get('tab_chat_label'),
      value: MessengerTab.Conversation,
      icon: (
        <SvgIconChat
          className={
            styles[activeMessengerTab === MessengerTab.Conversation ? 'tab-active' : 'tab-icon']
          }
          style={ICON_STYLE}
        />
      ),
      badgeContent: getUnreadMessages(chatList),
    });

  const handleTabChange = (newValue: MessengerTab) => {
    setUIGlobalState({
      ...UIGlobalState,
      messengerOpen: true,
    });
    dispatcher.start(SelectMessengerTab(newValue));
  };

  const onBurgerClick = () => {
    if (tabStack.includes(MessengerTab.Menu)) {
      setUIGlobalState({ ...UIGlobalState, messengerOpen: !UIGlobalState.messengerOpen });
      return;
    }
    dispatcher.start(OpenPage(MessengerTab.Menu));
  };

  return (
    <div
      className={styles['container']}
      style={{
        borderRight: UIGlobalState.messengerOpen ? '1px solid rgba(255,255,255,0.2)' : 'none',
      }}
    >
      <Button
        fullWidth
        onClick={onBurgerClick}
        disabled={activeMessengerTab === MessengerTab.Analytics}
      >
        <div className={styles['tab']}>
          <StyledBadge
            color="primary"
            showZero={false}
            componentsProps={COMPONENT_PROPS}
            badgeContent={0}
            anchorOrigin={ANCHOR_ORIGIN}
            style={{ width: '100%' }}
          >
            <TabContent
              label=""
              icon={<BurgerIcon className={styles['tab-icon']} style={ICON_STYLE} />}
              active={false}
            />
          </StyledBadge>
        </div>
      </Button>
      {tabs
        .filter((item) => (isAnalyticsAllowed ? true : item.value !== MessengerTab.Analytics))
        .map(({ label, value, icon, badgeContent }) => (
          <Button key={label} fullWidth onClick={() => handleTabChange(value)}>
            <div className={styles['tab']}>
              <AnimatedLine visible={activeMessengerTab === value} />
              <StyledBadge
                color="primary"
                showZero={false}
                componentsProps={COMPONENT_PROPS}
                badgeContent={badgeContent}
                anchorOrigin={ANCHOR_ORIGIN}
                style={{ width: '100%' }}
              >
                <TabContent label={label} icon={icon} active={activeMessengerTab === value} />
              </StyledBadge>
            </div>
          </Button>
        ))}
    </div>
  );
}

export default AppDrawer;

interface TabContentProps {
  label: string;
  icon: React.ReactNode;
  active: boolean;
}

const TabContent: FC<TabContentProps> = ({ label, active, icon }) => {
  const labelStyle = {
    color: active ? 'white' : 'gray',
  };
  return (
    <div className={styles['tab-content']}>
      {icon}
      <p className={styles['tab-label']} style={labelStyle}>
        {label}
      </p>
    </div>
  );
};

interface AnimatedLineProps {
  visible: boolean;
}

const AnimatedLine: FC<AnimatedLineProps> = ({ visible }) => {
  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{
            position: 'absolute',
            backgroundColor: '#1cade4',
            height: 70,
            width: 2,
            left: 0,
            borderRadius: 10,
          }}
        />
      )}
    </AnimatePresence>
  );
};
